import { useEffect, useState, lazy } from "react";
import "../assets/css/style.css";
import Hero from "./sections/Hero";
import Header from "./sections/Header";
import Background from "./sections/Background";

const About = lazy(() => import("./sections/About"));
const Works = lazy(() => import("./sections/Works"));
const Skills = lazy(() => import("./sections/Skills"));
const Contact = lazy(() => import("./sections/Contact"));
const Footer = lazy(() => import("./sections/Footer"));

function App() {
  const [isDark, setDark] = useState(false);

  useEffect(() => {
    const prefersDark = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;

    if (prefersDark) {
      setDark(true);
    }

    const theme = localStorage.getItem("theme");
    if (theme === "dark") {
      setDark(true);
    }
  }, []);

  return (
    <>
      <div className={`${isDark ? "dark " : ""}`}>
        <Background isDark={isDark} />
        <Header isDark={isDark} setDark={setDark} />
        <Hero />
        <About />
        <Works />
        <Skills />
        <Contact />
        <Footer />
      </div>
    </>
  );
}

export default App;
